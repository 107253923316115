/**
 * header is responsible for opening and closing the
 * main header bar menu
 */

/* the button used to open the menu */
let mainNavigation;
let menuButton;
let menuElement;
let logolinkClass = "blue"; // Default colour for the logo
let logo;
let primaryPage;

/* initialise called on load */
export default function initialize() {
    // set the main navigation variable
    mainNavigation = document.querySelector("#mainNavigation");
    if (!mainNavigation) {
        return;
    }

    primaryPage = document.querySelector("body");

    logolinkClass = getColourClass(mainNavigation.querySelector("a"));
    logo = mainNavigation.querySelector("svg");

    // set the menu button variable
    menuButton = mainNavigation.querySelector("#mainMenuButton");

    stickyNavigation();

    // set the menu element
    menuElement = mainNavigation.querySelector("div[aria-labelledby='mainMenuButton']");
    if (menuButton) {
        // add the toggle menu listener
        menuButton.addEventListener("click", toggleMenu);
    }

    window.addEventListener("scroll", stickyNavigation);

    const openForPreview = mainNavigation.getAttribute("data-open");
    if (openForPreview === "true") {
        toggleMenu();
    }
}

/** Get the class for the Logo or Menu button (to set their primary colour)
 * @note this method, `models.common.py` `LOGO_COLOURS`
 * and `scss._navbar.scss` `nav#mainNavigation`
 * must all match
*/
function getColourClass(elem, defaultColour="blue") {
    return elem.classList.contains("white") ? "white" : defaultColour;
}

function scrollSwap(elem, elemColourClass) {
    if (!primaryPage) {
        return;
    }
    if (window.scrollY > 0) {
        elem.classList.remove(elemColourClass);
        elem.classList.add("blue");
    } else {
        elem.classList.remove("blue");
        elem.classList.add(elemColourClass);
    };
}

function stickyNavigation() {
    if (window.scrollY > 0) {
        mainNavigation.style.boxShadow = "0 1px 1px 0 rgb(0 0 0 / 15%)";
        mainNavigation.style.backgroundColor = "#FFFFFF";
    } else {
        mainNavigation.style.boxShadow = "";
        mainNavigation.style.backgroundColor = "transparent";
    };

    scrollSwap(logo, logolinkClass);
}

/* open the menu */
function toggleMenu() {
    const menuOpen = mainNavigation.hasAttribute("data-bs-expanded");
    if (!menuOpen) {
        mainNavigation.setAttribute("data-bs-expanded", "");
        menuButton.setAttribute("aria-expanded", true);
        menuElement.setAttribute("aria-hidden", false);
        menuElement.hidden = false;
        document.body.style.overflowY = "hidden";
        if (!window.scrollY && primaryPage) {
            logo.classList.remove(logolinkClass);
            logo.classList.add("blue");
        };
        return;
    }

    mainNavigation.removeAttribute("data-bs-expanded");
    menuButton.setAttribute("aria-expanded", false);
    menuElement.setAttribute("aria-hidden", true);
    menuElement.hidden = true;
    document.body.style.overflowY = "auto";
    scrollSwap(logo, logolinkClass);
}

// If hot reloading we should remove the old menu open handler
if (module.hot) {
    module.hot.dispose((data) => {
        if (menuButton) {
            menuButton.removeEventListener("click", toggleMenu);
        }
    });
}
